const style = theme => ({
  midscreenCont: theme.classes.midscreenCont,
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#FEF9E4',
  },
  headline: {
    ...theme.classes.headline,
    position: 'fixed',
    padding: `${theme.spacing(2)}px 11px`,
    width: 'calc(100% - 28px)',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 16px',
      margin: 0,
      backgroundColor: '#FEF9E4',
      zIndex: 2,
    },
  },
  subheadline: {
    ...theme.classes.headline,
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      padding: `${theme.spacing(2)}px 11px`,
    },
  },
  halfColorBody: {
    minHeight: `calc(100vh - 83px)`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `-14px`,
      width: '100vw',
      padding: '20px 14px 0',
      background: `linear-gradient(90deg, #FFF 300px, #FEF9E4 300px)`,
    },
  },
  menuColumnBody: {
    paddingTop: 42,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      paddingTop: 74,
    },
  },
  menuSpace: {
    [theme.breakpoints.up('md')]: {
      width: 254,
    }
  },
  foodMenuArea: {
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      maxWidth: 754,
      marginTop: -7,
    },
  },
  noteInp: {
    flexGrow: 1,
    position: 'relative',
  },
  noteSuggestBox: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chipBrowseArea: {
    marginTop: theme.spacing(1.5),
  },
  noteChip: {
    margin: theme.spacing(0.5, 0.25),
  },
  noteChipBrowse: {
    margin: theme.spacing(0.5),
  },
  noteInpRoot: {
    flexWrap: 'wrap',
  },
  noteInpInput: {
    width: 'auto',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      minWidth: 320,
    },
  },
  suggestHead: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
  },
});

export default style;
import { initialize } from 'redux-form';
import {
  INIT_NEW_CUSTOMER,
  UPDATE_CUSTOMER,
} from 'state/customer/types';
import { getCustomerHistory, getCustomer } from 'state/customer/selectors';

export const initNewCustomer = () => ({
  type: INIT_NEW_CUSTOMER,
});

export const updateCustomer = payload => ({
  type: UPDATE_CUSTOMER,
  payload,
});

export const beginCustomer = () => (dispatch, getState) => {
  const state = getState();
  const custs = getCustomerHistory(state);
  if (Object.keys(custs).length === 0) {
    dispatch(initNewCustomer());
  }
};

export const autofillCustomer = () => (dispatch, getState) => {
  const state = getState();
  const customer = getCustomer(state);
  const { name, instructions, address, contact, paymethod, orderType } = customer;
  dispatch(initialize('checkout', {
    name,
    instructions,
    address,
    orderType: orderType || 'delivery',
    contact,
    paymethod,
  }));
};
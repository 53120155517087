import React from 'react';
import TextField from '@material-ui/core/TextField';

const RenderTextField = ({
  input,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    id={input.name}
    {...input}
    error={touched && !!error}
    helperText={touched && error}
    {...custom}
  />
);

export default RenderTextField;

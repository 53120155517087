import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const addStyle = withStyles(theme => ({
  midscreen: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#FEF9E4',
    ...theme.classes.midscreenCont,
  },
  chunk: {
    minHeight: 350,
    ...theme.classes.midscreenCont,
  },
}));

const Loader = ({ page, classes }) => (
  <div className={page ? classes.midscreen : classes.chunk}>
    <CircularProgress />
  </div>
);

Loader.propTypes = {
  page: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

Loader.defaultProps = {
  page: false,
};

export default addStyle(Loader);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import withTheme from 'ui/withTheme';

import style from 'ui/menu/FoodMenuItem.style';

const FoodMenuItem = ({
  food,
  onOrderingItem,
  classes,
}) => {
  const [promptEl, setPromptEl] = React.useState(null);
  const [typeChosen, setTypeChosen] = React.useState('');
  const [menuEl, setMenuEl] = React.useState(null);

  const handleClick = event => {
    if (food.lg_price || food.xl_price) {
      setMenuEl(event.currentTarget);
    } else {
      setTypeChosen('main');
      setPromptEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setMenuEl(null);
  };
  
  const handleCloseMenus = () => {
    handleClose();
    setPromptEl(null);
  };

  const handleSizeTypeSelected = (event) => {
    setTypeChosen(event.currentTarget.dataset.sizeType);
    setPromptEl(event.currentTarget);
  };

  const promptProps = {
    subheader: (
      <ListSubheader component="div" id="prompt-subheader">
        Any Instructions?
      </ListSubheader>
    )
  };

  const handleDelegation = event => {
    const addNotes = event.currentTarget.dataset.ans === 'Y';
    onOrderingItem(
      food,
      typeChosen,
      addNotes,
    );
    handleCloseMenus();
  };

  const menuListProps = {
    subheader: (
      <ListSubheader component="div" id="nested-list-subheader">
        Choose
      </ListSubheader>
    )
  };

  return (
    <Grid item xs={6} sm={4} md={3} className={classes.fooditem}>
      <div className={classes.thumbgroup}>
        <img src={food.image.sizes.medium.source_url} className={classes.thumb} loading="lazy" />
        <Fab
          color="primary"
          aria-label="add"
          className={classes.thumbfab}
          classes={{ root: classes.thumbfabroot }}
          aria-controls="add-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AddIcon />
        </Fab>
        <Menu
          id="add-menu"
          anchorEl={menuEl}
          keepMounted
          open={Boolean(menuEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={menuListProps}
        >
          <MenuItem onClick={handleSizeTypeSelected} data-size-type="main">
            <ListItemText primary="Medium" secondary={`$${food.main_price}`} />
          </MenuItem>
          {food.lg_price && (
            <MenuItem onClick={handleSizeTypeSelected} data-size-type="lg">
              <ListItemText primary="Large" secondary={`$${food.lg_price}`} />
            </MenuItem>
          )}
          {food.xl_price && (
            <MenuItem onClick={handleSizeTypeSelected} data-size-type="xl">
              <ListItemText primary="Extra Large" secondary={`$${food.xl_price}`} />
            </MenuItem>
          )}
        </Menu>
        <Menu
          id="add-notes"
          anchorEl={promptEl}
          keepMounted
          open={Boolean(promptEl)}
          onClose={handleCloseMenus}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={promptProps}
        >
          <MenuItem onClick={handleDelegation} data-ans="Y">Yes</MenuItem>
          <MenuItem onClick={handleDelegation} data-ans="N">No</MenuItem>
        </Menu>
      </div>
      <div className={classes.defprod}>
        <Typography variant="caption">${`${food.main_price}${food.qty_type && food.qty_type === 'pound' ? ' per Pound' : ''}`}</Typography>
        <Typography variant="h6" className={classes.foodname}>{food.name}</Typography>
        <Typography variant="body2">{food.description}</Typography>
      </div>
    </Grid>
  );
};

FoodMenuItem.propTypes = {
  food: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}),
  onOrderingItem: PropTypes.func.isRequired,
};

export default withTheme(withStyles(style)(FoodMenuItem));
export const toMap = (list, indexKey) => (
  list.reduce((acc, curr) => {
    const key = curr[indexKey];
    acc[key] = curr;
    return acc;
  }, {})
);

export const separateLast = (list) => {
  if (!list || list.length === 0) return [[], {}];
  const previouses = list.slice(0, list.length - 2);
  const current = list[list.length - 1];
  return [ previouses, current ];
};

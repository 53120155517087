import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const CartItem = ({ classes, item, onRemoveItem, onChangeItem }) => {
  const onDeleteClicked = () => onRemoveItem(item);
  const onChangeQtyField = e => onChangeItem(item, e.currentTarget.value);
  const onQtyIncrement = () => onChangeItem(item, item.qty + 1);
  const onQtyDecrement = () => onChangeItem(item, item.qty - 1);
  return (
    <Grid item xs={12} className={classes.flexContainer}>
      <div className={classes.leftSide}><img src={item.image.sizes.medium.source_url} className={classes.foodPhotos}/>
        <TextField
          variant="outlined"
          value={item.qty}
          className={classes.qtyField}
          align="center"
          margin="dense"
          size="small"
          onChange={onChangeQtyField}
          InputProps={{
            classes: { root: classes.qtyFieldBase, input: classes.qtyFieldIn },
            startAdornment: (
              <IconButton
                edge="start"
                size="small"
                disabled={item.qty < 2}
                onClick={onQtyDecrement}
              >
                <RemoveIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                edge="end"
                size="small"
                onClick={onQtyIncrement}
              >
                <AddIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div className={classes.rightSide}>
        <Typography variant="body1" className={classes.foodTitle}>{item.name}</Typography>
        {item.type ? (
          <Typography variant="body2">Size: {item.type === 'main' ? 'Medium' : (item.type === 'lg' ? 'Large' : 'Extra Large')}</Typography>
        ) : null}
        <Typography variant="body2" className={classes.notesStyle}>Notes:&nbsp;
        {item.notes || 'N/A'}
        </Typography>
        <div className={classes.deleteButton} onClick={onDeleteClicked}><IconButton aria-label="delete"><DeleteIcon /></IconButton></div>
      </div>
    </Grid>
  );
};

CartItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  item: PropTypes.shape({}).isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

export default CartItem;

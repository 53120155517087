import {
  SET_WPPAGE_SITE,
  SET_WPPAGE_LIST,
  SET_WPPAGE_SELECTED,
  PUSH_WPPAGE_TOLIST,
} from 'state/pages/types';

export const links = {
  menu: {
    link: '/',
    slug: 'home',
    title: {
      rendered: 'Menu',
    },
  },
  retailers: {
    link: '/retailers',
    slug: 'retailers',
    title: {
      rendered: 'Retailers',
    },
  },
  contact: {
    link: '/contact',
    slug: 'contact',
    title: {
      rendered: 'Contact',  
    },
  },
  cart: {
    link: '/cart',
    slug: 'cart',
    title: {
      rendered: 'Cart',  
    },
  }
};

const initState = {
  info: {},
  list: [],
  listmap: {},
  selected: {},
};

const toMap = pageinfo => pageinfo.reduce((acc, page) => {
  acc[page.slug] = { ...page, slug: page.slug === 'home' ? '' : page.slug };
  return acc;
}, {});

const reducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_WPPAGE_SITE:
      return { ...state, info: action.payload };
    case PUSH_WPPAGE_TOLIST:
      const page = action.payload;
      if (page.slug in state.listmap) {
        return state;
      }
      const _list = [...state.list, page];
      const _listmap = { ...state.listmap, [page.slug]: page };
      return { ...state, list: _list, listmap: _listmap };
    case SET_WPPAGE_LIST:
      const list = [
        links.menu,
        links.retailers,
        ...action.payload.reverse(),
        links.contact,
      ];
      const listmap = toMap(list);
      return { ...state, list, listmap };
    case SET_WPPAGE_SELECTED:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};

export default reducer;

import { combineReducers } from 'redux';
import { separateLast, toMap } from 'helpers/collection';
import {
  INIT_NEW_CUSTOMER,
  UPDATE_CUSTOMER,
} from 'state/customer/types';

const custState = {
  info: [],
  map: {},
  dateCreated: '',
  dateUpdated: '',
};

const list = (state = [], action = {}) => {
  const [previousCusts, cust] = separateLast(state);
  switch (action.type) {
    case INIT_NEW_CUSTOMER:
      return [
        ...state,
        {
          id: +new Date(),
        }
      ];
    case UPDATE_CUSTOMER:
      const updated = { ...cust, ...action.payload };
      return [...previousCusts, updated];
    default:
      return state;
  }
};

const reducer = combineReducers({ list });

export default reducer;

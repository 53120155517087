import { separateLast, toMap } from 'helpers/collection';
import { combineReducers } from 'redux';
import { 
  INIT_NEW_CART,
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  PATCH_ITEM_CART,
} from 'state/orders/types';

const cartState = {
  list: [],
  map: {},
  dateCreated: '',
  dateUpdated: '',
};

const reAttachOrder = (prevOrders, order, list, map) => ([
  ...prevOrders,
  { ...order, list, map, dateUpdated: +new Date() },
]);

const list = (state = [], action = {}) => {
  const [previousOrders, order] = separateLast(state);
  let newList, newMap;
  switch (action.type) {
    case INIT_NEW_CART:
      return [
        ...state,
        {
          ...cartState,
          dateCreated: +new Date(),
          dateUpdated: +new Date(),
        },
      ];
    
    case ADD_ITEM_TO_CART:
      const { id, qty_type, type } = action.payload;
      const similarCartItemFinder = item => (
        item.id === id && item.qty_type === qty_type && item.type === type
      );
      const whereSimilar = order.list.findIndex(similarCartItemFinder);
      if (whereSimilar > -1) {
        const itemUpdate = { ...order.list[whereSimilar] };
        const newPayload = {
          ...itemUpdate,
          qty: itemUpdate.qty + action.payload.qty,
        };
        order.list[whereSimilar] = newPayload;
        newList = [ ...order.list ];
      } else {
        newList = [
          ...order.list,
          {
            ...action.payload,
            dateAdded: +new Date(),
          },
        ];
      }
      newMap = toMap(newList, 'dateAdded');
      return reAttachOrder(previousOrders, order, newList, newMap);
    case REMOVE_ITEM_FROM_CART:
      newList = order.list.filter(item => item.dateAdded !== action.payload);
      newMap = toMap(newList, 'dateAdded');
      return reAttachOrder(previousOrders, order, newList, newMap);
    case PATCH_ITEM_CART:
      const { key } = action.payload;
      newList = order.list.map((item) => {
        if (item.dateAdded === key) {
          return {
            ...item,
            ...action.payload.item,
            dateUpdate: +new Date(),
          };
        }
        return item;
      });
      newMap = toMap(newList, 'dateAdded');
      return reAttachOrder(previousOrders, order, newList, newMap);
    default:
      return state;  
  }
};

const reducer = combineReducers({ list });

export default reducer;

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import loading from 'state/loading/reducer';
import menu from 'state/menu/reducer';
import orders from 'state/orders/reducer';
import pages from 'state/pages/reducer';
import customer from 'state/customer/reducer';
import prompter from 'state/prompter/reducer';
import toasts from 'state/toasts/reducer';
import retailers from 'state/retailers/reducer';

const reducerDef = {
  form,
  loading,
  menu,
  retailers,
  orders: persistReducer({ key: 'orders', storage }, orders),
  pages,
  customer: persistReducer({ key: 'customer', storage }, customer),
  prompter,
  toasts,
};

// app root reducer
const reducer = combineReducers(reducerDef);

export default reducer;

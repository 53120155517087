import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TruckIcon from '@material-ui/icons/LocalShipping';
import MarkerIcon from '@material-ui/icons/LocationOn';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const withStylesCard = withStyles(theme => ({
  root: {
    '& a': {
      color: 'initial',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  fixWidth: {
    [theme.breakpoints.up('md')]: {
      width: 238,
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  deliverIcon: {
    verticalAlign: 'middle',
  },
  tblCell: {
    fontSize: '0.775em',
  },
  cardButtonArea: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& a:last-of-type': {
      marginLeft: 0,
    },
  },
}));


const ContactCard = ({
  noElevation,
  fixWidth,
  classes,
  noVisitButton,
  onButtonClicked,
}) => (
  <Card
    elevation={noElevation ? 0 : 1}
    className={clsx(classes.root, fixWidth && classes.fixWidth)}
  >
    <CardContent>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Order now
      </Typography>
      <Typography variant="h5" component="h2">
        <a href="tel:1-306-862-4001">1-306-862-4001</a>
      </Typography>
      <Typography className={classes.pos} color="textSecondary">
        <TruckIcon className={classes.deliverIcon} /> Debit Delivery
      </Typography>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Opens at:
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.tblCell}>Sun - Thu</TableCell>
            <TableCell className={classes.tblCell}>11am - 1am</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tblCell}>Fri - Sat</TableCell>
            <TableCell className={classes.tblCell}>11am - 3am</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
    <CardActions className={classes.cardButtonArea}>
      {!noVisitButton && (
        <Button
          component={Link}
          to="/contact"
          size="small"
          startIcon={<MarkerIcon />}
          onClick={onButtonClicked}
        >
          Visit Us
        </Button>
      )}
      <Button
        component="a"
        href="/wp-content/uploads/2020/06/wbp-menu.pdf"
        target="_blank"
        size="small"
        startIcon={<PDFIcon />}
        onClick={onButtonClicked}
      >
        Download our Menu
      </Button>
    </CardActions>
    
  </Card>
);

ContactCard.propTypes = {
  noElevation: PropTypes.bool,
  fixWidth: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
  noVisitButton: PropTypes.bool,
  onButtonClicked: PropTypes.func,
};

ContactCard.defaultProps = {
  noElevation: false,
  fixWidth: false,
  noVisitButton: false,
  onButtonClicked: () => {},
};

export default withStylesCard(ContactCard);

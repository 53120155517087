import withTheme, { SPACING_UNIT } from 'ui/withTheme';

const style = theme => ({
  headline: {
    ...theme.classes.headline,
    padding: `${theme.spacing(2)}px 11px`,
    width: 'calc(100% - 28px)',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 16px',
      margin: 0,
      backgroundColor: '#FEF9E4',
    },
  },
  lineBlock: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px 11px`,
    width: 768,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 16px',
      width: 'calc(100% - 28px)',
      backgroundColor: '#FEF9E4',
    },
  },
  background: {
    width: '100%',
    minHeight: `calc(100vh - 83px)`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `-14px`,
      width: '100vw',
      padding: '20px 14px 0',
      background: `linear-gradient(90deg, #FFF 830px, #FEF9E4 830px)`,
    },
  },

  foodPhotos: {
    borderRadius: '50%',
    width: 100,
    height: 100,
    marginRight: 10,
    [theme.breakpoints.up('md')]: {
      width: 168,
      height: 168,
    },
  },

  qtyField: {
    marginRight: 10,
    width: 100,
  },

  qtyFieldBase: {
    padding: '0 5px',
  },

  qtyFieldIn: {
    fontSize: 12,
    textAlign: 'center',
  },

  flexContainer: {
    display: 'flex',
    paddingBottom: 30,
  },

  leftSide: {
    textAlign: 'center',
    width: 114,
    [theme.breakpoints.up('md')]: {
      width: 178,
    },
  },

  rightSide: {
    flex: 1,
  },

  notesStyle: {
    display: 'block',
    borderRadius: 15,
    backgroundColor: '#EEEEEE',
    padding: '15px 20px',
    marginTop: 15,
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },

  deleteButton: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },

  checkoutButton: {
    marginTop: '10px',
    height: '90px',
    textTransform: 'none',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '20px',
      width: '349px',
      height: '90px',
    },
  },

  checkoutButtonLabel: {
    flexDirection: 'column',
  },

  backButton: {
    marginTop: '10px',
    textTransform: 'none',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '12px',
      width: '349px',
    },
  },

  outercheckoutButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 768,
      textAlign: 'right',
    },
  },

  outercheckoutGrid: {
    paddingBottom: 15,
  },

  foodTitle: {
    fontWeight: 'bold',
  },
});

export default style;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ContactCard from 'ui/common/ContactCard';
import style from 'ui/retailers/Retailers.style';
import Loader from 'ui/common/Loader';
import LocationMap from 'ui/common/LocationMap';

const withStylesRetailers = withStyles(style);

const ModContactCard = withStyles(theme => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))(ContactCard);

const RetailersBody = ({ loading, gatherRetailers, retailers, classes }) => {
  useEffect(() => {
    gatherRetailers();
  }, []);
  return (
    <div className={classes.body}>
      <div className={classes.content}>
        <Typography variant="h4" component="h1" className={classes.headline}>Retailers</Typography>
        {loading && <Loader />}
        {!loading && retailers.length > 0 && (
          <table className="special">
            <thead>
              <tr>
                <th>Locations</th>
              </tr>
            </thead>
            <tbody>
              {retailers.map((retailer, idx) => (
                <tr key={retailer.id}>
                  <td>
                    <strong className="num">{idx+1}. {retailer.name}, {retailer.address}</strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={classes.side}>
        <LocationMap markers={retailers} />
        <ModContactCard />
      </div>
    </div>
  );
};

RetailersBody.propTypes = {
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  ).isRequired,
  gatherRetailers: PropTypes.func.isRequired,
};

const Retailers = withStylesRetailers(RetailersBody);

export default Retailers;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import withTheme from 'ui/withTheme';

let displayed = [];

const ToastProvider = ({
  notifications,
  enqueueSnackbar,
  closeSnackbar,
  history,  
}) => {
  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };
  const removeDisplayed = (e, id) => {
    closeSnackbar(id);
    displayed = [...displayed.filter(key => id !== key)];
  };
  React.useEffect(() => {
    Object.entries(notifications).forEach(([key, { action, message, type: variant }]) => {
      if (displayed.includes(key)) return;

      const options = {
        variant,
        onExited: removeDisplayed,
      };
      if (action) {
        options.action = () => (
          <Button onClick={() => (
            history.push(action.link)
          )}>{action.label}</Button>
        )
      }
      enqueueSnackbar(message, options);

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);
  return null;
};

ToastProvider.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notifications: PropTypes.shape({}).isRequired, // eslint-disable-line react/forbid-prop-types
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default withTheme(withRouter(ToastProvider));

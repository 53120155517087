import {
  SET_RETAILERS,
} from 'state/retailers/types';

import { toggleLoading } from 'state/loading/actions';
import { getRetailers } from 'api/wprest';

export const setRetailers = payload => ({
  type: SET_RETAILERS,
  payload,
});

// thunks

export const fetchRetailers = () => dispatch => new Promise((resolve) => {
  dispatch(toggleLoading('retailers'));
  getRetailers().then((res) => {
    dispatch(toggleLoading('retailers'));
    dispatch(setRetailers(res));
    resolve(res);
  });
});

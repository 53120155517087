import {
  SET_WPPAGE_SITE,
  SET_WPPAGE_LIST,
  SET_WPPAGE_SELECTED,
  PUSH_WPPAGE_TOLIST,
} from 'state/pages/types';
import { toggleLoading } from 'state/loading/actions';
import { getWPPages, getWP, getWPPageBySlug } from 'api/wprest';
import { getPageBySlug, getRawPageList } from 'state/pages/selectors';

export const setPageInfo = payload => ({
  type: SET_WPPAGE_SITE,
  payload,
});

export const setPageList = payload => ({
  type: SET_WPPAGE_LIST,
  payload,
});

export const pushPageToList = payload => ({
  type: PUSH_WPPAGE_TOLIST,
  payload,
});

export const setPageSelected = payload => ({
  type: SET_WPPAGE_SELECTED,
  payload,
});

// thunks

export const fetchPages = () => (dispatch, getState) => new Promise((resolve) => {
  dispatch(toggleLoading('wppages'));
  Promise.all([getWP(), getWPPages()]).then(([info, pages]) => {
    dispatch(setPageInfo(info));
    dispatch(setPageList(pages));
    dispatch(toggleLoading('wppages'));
    const state = getState();
    resolve({ info, pages: getRawPageList(state) });
  })
});

export const fetchPageBySlug = slug => (dispatch, getState) => new Promise((resolve) => {
  const state = getState();
  const pageresult = getPageBySlug(slug)(state);
  let prom;
  if (!pageresult) {
    prom = getWPPageBySlug(slug).then((pages) => {
      const page = pages[0];
      dispatch(pushPageToList(page));
      return page;
    });
  } else {
    prom = Promise.resolve(pageresult);
  }
  prom.then((page) => {
    dispatch(setPageSelected(page));
    resolve(page);
  });
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { get } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const suggestionDict = {
  pizza: [
    { label: 'add onions', pts: 4, type: 'Add' },
    { label: 'add mushroom', pts: 3, type: 'Add' },
    { label: 'add green pepper', pts: 3, type: 'Add' },
    { label: 'add jalapeno', pts: 3, type: 'Add' },
    { label: 'add banana pepper', pts: 3, type: 'Add' },
    { label: 'add shrimp', pts: 3, type: 'Add' },
    { label: 'add black olives', pts: 4, type: 'Add' },
    { label: 'add pickles', pts: 4, type: 'Add' },
    { label: 'add cheese', pts: 4, type: 'Add' },
    { label: 'add cheddar', pts: 4, type: 'Add' },
    { label: 'add feta cheese', pts: 4, type: 'Add' },
    { label: 'minus onions', type: 'Minus' },
    { label: 'minus mushroom', type: 'Minus' },
    { label: 'minus green pepper', type: 'Minus' },
    { label: 'minus jalapeno', type: 'Minus' },
    { label: 'minus banana pepper', type: 'Minus' },
    { label: 'minus shrimp', type: 'Minus' },
    { label: 'minus black olives', type: 'Minus' },
    { label: 'minus pickles', type: 'Minus' },
    { label: 'minus cheese', type: 'Minus' },
    { label: 'minus cheddar', type: 'Minus' },
    { label: 'minus feta cheese', type: 'Minus' },
  ],
  sides: [
    { label: 'air fry', pts: 4, type: 'Add' },
  ],
  drinks: [
    { label: 'not cold', pts: 4, type: 'Add' },
  ],
  dips: [
    { label: 'not cold', pts: 4, type: 'Add' },
  ],
};

const renderInput = (inputProps) => {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.noteInpRoot,
          input: classes.noteInpInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
};

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

const getSuggestions = (value, suggestions, { showEmpty = false } = {}) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
};

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
};

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

let popperNode;

const NoteDialog = ({
  open,
  classes,
  chosen,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);

  const embedded = get(chosen, 'embedded', {});
  const terms = get(embedded, 'wp:term', []);
  const slug = get(terms, '0.0.slug', '');


  const groupedSuggestions = !slug ? {} : suggestionDict[slug].reduce((acc, curr) => {
    const { type } = curr;
    const typegroup = get(acc, type, []);
    return {
      ...acc,
      [type]: [
        ...typegroup,
        curr,
      ],
    };
  }, {});

  const suggestions = get(suggestionDict, slug, []);

  const handleKeyDown = event => {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  };

  const handleKeyUp = event => {
    if (event.key === 'Enter' && inputValue.length) {
      handleChange(event.target.value);
    }
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleChange = item => {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    setSelectedItem(newSelectedItem);
  };

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
  };

  const onEntered = () => {
    setSelectedItem([]);
    setInputValue('');
    popperNode.focus();
  };

  const onClickSubmit = () => {
    const notes = [ ...selectedItem, (inputValue && inputValue) ];
    onSubmit(notes.join('; '));
  };
  return (
    <Dialog open={open} onEntered={onEntered}>
      <DialogContent>
        <Downshift
          id="notechips"
          inputValue={inputValue}
          onChange={handleChange}
          selectedItem={selectedItem}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue: inputValue2,
            selectedItem: selectedItem2,
            highlightedIndex,
          }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onKeyDown: handleKeyDown,
              onKeyUp: handleKeyUp,
            });

            console.log(chosen);

            return (
              <div className={classes.noteInp}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: `Your instructions for ${chosen.name}`,
                  InputLabelProps: getLabelProps(),
                  InputProps: {
                    startAdornment: selectedItem.map(item => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        className={classes.noteChip}
                        onDelete={handleDelete(item)}
                      />
                    )),
                    onBlur,
                    onChange: event => {
                      handleInputChange(event);
                      onChange(event);
                    },
                    onFocus,
                  },
                  inputProps,
                  ref: node => {
                    popperNode = node;
                  },
                })}

                <Popper style={{ position: 'relative', zIndex: 1400 }} open={isOpen} anchorEl={popperNode}>
                  <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                    <Paper
                      square
                      style={{ marginTop: 8, width: popperNode ? popperNode.clientWidth : undefined }}
                    >
                      {getSuggestions(inputValue, suggestions).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion.label }),
                          highlightedIndex,
                          selectedItem,
                        }),
                      )}
                    </Paper>
                  </div>
                </Popper>
              </div>
            );
          }}
        </Downshift>
        <div className={classes.chipBrowseArea}>
          <Typography variant="caption" display="block" className={classes.suggestHead}>or, some suggestions that you can add to your instructions:</Typography>
          {Object.keys(groupedSuggestions).map(typename => (
            <Accordion key={typename}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="caption">{typename}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {groupedSuggestions[typename].map(suggestion => (
                    <Chip
                      key={suggestion.label}
                      size="small"
                      onClick={() => handleChange(suggestion.label)}
                      className={classes.noteChipBrowse}
                      key={suggestion.label}
                      label={suggestion.label}
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}          
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickSubmit}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

NoteDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
  chosen: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
};

NoteDialog.defaultProps = {
  open: false,
  chosen: {},
};

export default NoteDialog;
import {
  SET_MENU,
  SET_MENU_CATEGORIES,
} from 'state/menu/types';

const initalState = {
  list: [],
  categories: [],
};

const reducer = (state = initalState, action = {}) => {
  switch (action.type) {
    case SET_MENU:
      return { ...state, list: action.payload };
    case SET_MENU_CATEGORIES:
        return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default reducer;

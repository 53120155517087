import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import withTheme from 'ui/withTheme';
import clsx from 'clsx';
import style from 'ui/page/orderreview/OrderReview.style';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const withStylesOrderReview = withStyles(style);

const numberWithCommas = (x) => (
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

const OrderReviewBody = ({ classes, customer, cart, onBack, onConfirmOrder, loading, onGotoCart }) => {
  const submitOrder = () => onConfirmOrder(customer, cart);
  return (
    <>
      <div className={classes.background}>
        <Typography variant="h4" component="h1" className={classes.headline}>Order Review</Typography>
        <Typography variant="subtitle2" className={classes.title}>Please review and confirm your order</Typography>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.yourCart}>
            {cart.list.map(cartitem => (
              <div className={classes.item} key={cartitem.dateAdded}>
                <img src={cartitem.image.sizes.medium.source_url} className={clsx(classes.leftSide, classes.foodPhotos)} />
                <div className={classes.itemInfo}>
                  <Typography variant="body1" className={clsx(classes.rightSide, classes.foodTitle)}>{cartitem.name} x {cartitem.qty}</Typography>
                  {cartitem.notes && (
                    <Typography variant="body2" className={classes.notesStyle}>Notes:&nbsp;
                      {cartitem.notes}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </Grid>
            
          <Grid item xs={12} md={6} className={classes.yourAddress}>
            <div className={classes.wholeRight}>
              <div className={classes.rightSection}>
                <Typography variant="subtitle2" className={classes.rightSectionTitle}>Order Type</Typography>
                <Typography variant="body1" className={classes.name}>{customer.orderType === 'delivery' ? 'Delivery' : 'Pick-up'}</Typography>
              </div>
              <div className={classes.rightSection}>
                <Typography variant="subtitle2" className={classes.rightSectionTitle}>Your Information</Typography>
                <Typography variant="body1" className={classes.name}>{customer.name}</Typography>
                <Typography variant="body1" className={classes.contact}>{customer.contact}</Typography>
                {customer.orderType === 'delivery' && (
                  <Typography variant="body1" className={classes.address}>{customer.address}</Typography>
                )}
              </div>
              {customer.instructions && (
                <div className={classes.rightSection}>
                  <Typography variant="subtitle2" className={classes.rightSectionTitle}>Notes/Instructions:</Typography>
                  <Typography variant="body1" className={classes.name}>{customer.instructions}</Typography>
                </div>
              )}
              {customer.orderType === 'delivery' && (
                <div className={classes.rightSection}>
                  <Typography variant="subtitle2" className={classes.rightSectionTitle}>Payment Method</Typography>
                  <Typography variant="body1" className={classes.paymentmethodBody}>    
                  <span className={classes.payType}>{customer.paymethod}</span> 
                  </Typography>
                </div>
              )}
              <div className={classes.rightSection}>
                <div className={classes.outerconfirmButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={loading}
                    onClick={submitOrder}
                    className={classes.confirmButton}
                  >
                    {loading ? 'Sending...' : 'Confirm Order'}
                  </Button>
                  <Button
                    size="large"
                    disabled={loading}
                    onClick={onBack}
                    className={classes.cancelButton}
                  >
                    Back
                  </Button>
                  <Button
                    size="large"
                    disabled={loading}
                    onClick={onGotoCart}
                    className={clsx(classes.cancelButton, classes.dimButton)}
                  >
                    Back to Cart
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

OrderReviewBody.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  cart: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onGotoCart: PropTypes.func.isRequired,
  onConfirmOrder: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const OrderReview = withStylesOrderReview(OrderReviewBody);

export default withTheme(OrderReview);
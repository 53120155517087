import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const LocationMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFqR-3M8dcvt7L1JMoQRLR8wXUhQUfAB0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: 250, marginBottom: 16 }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ markers, center }) => (
  <GoogleMap defaultZoom={8} defaultCenter={center || { lat: 53.36160430, lng: -104.01884100 }}>
    {markers.length && markers.map(({ id, lat, lng }) => (
      <Marker key={id} position={{ lat, lng }} />
    ))}
  </GoogleMap>
));

export default LocationMap;

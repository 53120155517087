const style = theme => ({
  background: {
    background: 'white',
    padding: '20px 14px',
    margin: '0 -14px -14px',

    [theme.breakpoints.up('md')]: {
      background: `linear-gradient(90deg, #FFF 50vw, #FEF9E4 50vw)`,
      minHeight: 'calc(100vh - 83px)',
      borderRadius: '0vw',
      margin: '0 -14px -14px',
      padding: '20px 25px',
    },
  },

  headline: {
    ...theme.classes.headline,
    padding: `${theme.spacing(2)}px 0 5px`,
    width: 'calc(100% - 28px)',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '0 0 5px',
    },
  },

  title: {
    margin: `0 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      margin: `0 0 ${theme.spacing(4)}px`,
    },
  },

  yourCart: {
    display: 'flex',
    flexDirection: 'column',
  },

  yourAddress: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '0 50px',
    },
  },

  foodPhotos: {
    borderRadius: '50%',
    width: 42,
    height: 42,
    [theme.breakpoints.up('md')]: {
      width: 130,
      height: 130,
    },
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },

  itemInfo: {
    flex: '1',
  },

  notesStyle: {
    display: 'block',
    borderRadius: 15,
    backgroundColor: '#EEEEEE',
    padding: '15px 20px',
    marginTop: 15,
    [theme.breakpoints.up('md')]: {
      marginRight: 50,
    },
  },

  leftSide: {
    flexShrink: 0,
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      marginRight: 30,
    },
  },

  rightSide: {
    [theme.breakpoints.up('md')]: {
    alignSelf: 'center',
    width: 400,
    },
  },
  wholeRight: {
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },

  rightSection: {
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {

    },
  },
  payType: {
    textTransform: 'capitalize',
  },

  rightSectionTitle: {
    marginBottom: '10px',
    color: '#7e7e7e',
  },

  addressBody: {
    width: 242,
  },

  outerconfirmButton: {
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
        textAlign: 'left',
    },
  },

  confirmButton: {
    marginTop: '30px',
    width: '182px',

    [theme.breakpoints.up('md')]: {
        marginTop: '0px',
    },
  },

  cancelButton: {
    marginTop: '30px',
    width: '182px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },

  dimButton: {
    color: '#666',
    marginTop: '60px',
  },

});

export default style;
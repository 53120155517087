import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import PageContainer from 'ui/page/PageContainer';
import { SnackbarProvider } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import FoodMenuContainer from 'ui/menu/FoodMenuContainer';
import RetailersContainer from 'ui/retailers/RetailersContainer';
import ContactPageContainer from 'ui/page/ContactPageContainer';
import CartContainer from 'ui/page/CartContainer';
import CheckoutContainer from 'ui/page/checkout/CheckoutContainer';
import OrderReviewContainer from 'ui/page/orderreview/OrderReviewContainer';
import ConfirmDialogContainer from 'ui/common/dialog/ConfirmDialogContainer';
import ToastProviderContainer from 'ui/ToastProviderContainer';
import Loader from 'ui/common/Loader';
import withTheme from 'ui/withTheme';

import style from 'ui/App.style';
import ContactCard from 'ui/common/ContactCard';

const addStyle = withStyles(style);

class LinkTabBody extends Component {
  constructor(props) {
    super(props);
    this.linkClicked = this.linkClicked.bind(this);
  }

  linkClicked(ev) {
    const { history } = this.props;
    ev.preventDefault();
    history.push(ev.currentTarget.pathname);
  }

  render() {
    const { classes } = this.props;
    return (
      <Tab
        component="a"
        {...this.props}
        classes={{ root: classes.linkTab }}
        onClick={this.linkClicked}
      />
    );
  }
}

LinkTabBody.propTypes = {
  history: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const LinkTab = withTheme(addStyle(LinkTabBody));

class AppBody extends Component {
  state = {
    menuOpened: false,
  };

  constructor(props) {
    super(props);
    this.openCart = this.openCart.bind(this);
    this.menuClickToggle = this.menuClickToggle.bind(this);
  }

  componentDidMount() {
    const { onDidMount, gatherPages } = this.props;
    onDidMount();
    gatherPages();
  }

  setMenuOpened(menuOpened) {
    this.setState({ menuOpened });
  }

  openCart() {
    this.props.history.push('/cart');
  }

  menuClickToggle() { 
    this.setMenuOpened(!this.state.menuOpened);
  }

  render() {
    const {
      classes,
      loading,
    } = this.props;

    if (loading) {
      return <Loader page />;
    }

    const {
      history,
      slug,
      wpPages,
      hasCartItems,
    } = this.props;

    let pagelinks = wpPages.map(page => ({
      ...page,
      url: page.link,
      label: page.title,
    }));

    const menuItems = pagelinks.map(({ path, label }) => ({ url: path, label }));

    const drawer = (
      <div>
        <List>
          {menuItems.map(item => (
            <ListItem component={Link} to={item.url} button key={item.label} onClick={this.menuClickToggle}>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <ContactCard noElevation onButtonClicked={this.menuClickToggle} />
      </div>
    );

    const slugIs = slug => {
      if (slug === '') return 'home';
      return pagelinks.findIndex(p => p.slug === slug) !== -1 ? slug : false;
    };

    return (
      <SnackbarProvider maxSnack={3}>
        <div className={classes.root}>
          <AppBar className={classes.appBar}>
            <Paper className={classes.appBarPaper} elevation={0}>
              <div className={classes.appBarLeft} />
              <div className={classes.appBarMid}>
                <img src="/wp-content/themes/wildbillsv2/images/wildbillstoplogo.png" className={classes.mainLogo} />
                <Tabs
                  value={slugIs(slug)}
                  classes={{
                    root: classes.navHeaderTabRoot,
                  }}
                >
                  {pagelinks.map(({ label, path, slug: sl }) => (
                    <LinkTab label={label} value={sl} href={path} key={label} history={history} />
                  ))}
                </Tabs>
              </div>
              <IconButton
                className={classes.appBarRight}
                onClick={this.menuClickToggle}
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
            </Paper>
          </AppBar>
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor="right"
                open={this.state.menuOpened}
                onClose={this.menuClickToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <div className={classes.appbody}>
            <Switch>
              <Route path="/" exact component={FoodMenuContainer} />
              <Route path="/retailers" exact component={RetailersContainer} />
              <Route path="/contact" exact component={ContactPageContainer} />
              <Route path="/cart" exact component={CartContainer} />
              <Route path="/checkout" exact component={CheckoutContainer} />
              <Route path="/review" exact component={OrderReviewContainer} />
              <Route path="/:slug" component={PageContainer} />
            </Switch>
          </div>
          <Zoom in={hasCartItems}>
            <Fab className={classes.cartfab} color="primary" onClick={this.openCart}>
              <ShoppingCartIcon />
            </Fab>
          </Zoom>
          <ConfirmDialogContainer />
          <ToastProviderContainer />
        </div>
      </SnackbarProvider>
    );
  }
}

AppBody.propTypes = {
  onDidMount: PropTypes.func.isRequired,
  gatherPages: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  wpPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasCartItems: PropTypes.bool.isRequired,
};

AppBody.defaultProps = {
  loading: true,
};

const App = withTheme(addStyle(AppBody));

export default withRouter(App);
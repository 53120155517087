import { SPACING_UNIT } from 'ui/withTheme';

const drawerWidth = SPACING_UNIT * 10 * 3;

const style = theme => ({
  iconbox: theme.classes.iconbox,
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#FEF9E4',
  },
  appBar: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#FEF9E4',
      boxShadow: 'none',
    },
  },
  appBarPaper: {
    margin: '10px 10px 0',
    background: 'none',
    padding: '2px 4px 0',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '2px 4px',
      margin: 10,
    },
  },
  appBarLeft: {
    ...theme.classes.iconbox,
    [theme.breakpoints.up('md')]: {
      width: 0,
    },
  },
  appBarMid: {
    ...theme.classes.flex,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    },
  },
  mainLogo: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      margin: '0 0 12px',
    }
  },
  appBarRight: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navHeaderTabRoot: {
    display: 'none',
    marginLeft: SPACING_UNIT,
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      alignSelf: 'flex-end',
    },
  },
  linkTab: {
    color: '#fff',
    opacity: 1,
    paddingTop: 25,
    paddingBottom: 28,
    textTransform: 'capitalize',
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      minWidth: 100,
    },
  },
  cartfab: {
    position: 'fixed',
    bottom: SPACING_UNIT * 2,
    right: SPACING_UNIT * 2,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appbody: {
    padding: '86px 14px 14px',
    minHeight: 'calc(100vh - 83px)',
    [theme.breakpoints.up('md')]: {
      paddingTop: 93,
    },
  },
});

export default style;

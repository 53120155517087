import { createSelector } from 'reselect';
import { separateLast } from 'helpers/collection';

export const getCustomerState = state => state.customer;

export const getCustomerHistory = createSelector(getCustomerState, customer => customer.list);

const getDividedCustomers = createSelector(getCustomerHistory, customers => separateLast(customers));

export const getPreviousCustomers = createSelector(getDividedCustomers, ([previouses]) => previouses);
export const getCustomer = createSelector(getDividedCustomers, ([a, curr]) => curr);

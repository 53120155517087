const style = theme => ({

  headline: {
    ...theme.classes.headline,
    padding: `${theme.spacing(2)}px 0 5px`,
    width: 'calc(100% - 28px)',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '0 0 5px',
    },
  },

  background: {
    background: 'white',
    padding: '20px 14px',
    margin: '0 -14px -14px',

    [theme.breakpoints.up('md')]: {
      background: 'white',
      width: '60vw',
      minHeight: 'calc(100vh - 83px)',
      borderRadius: '0vw',
      margin: '0 0 -14px -14px',
      padding: '20px 25px',
    },
  },

  title: {
    margin: `0 0 ${theme.spacing(2)}px`,
  },

  textField: {
    width: '97%',
  },

  outernextButton: {
    textAlign: 'center',
    marginRight: '30px',

    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      marginRight: '30px',
    },
  },

  nextButton: {
    marginTop: '30px',
    width: '150px',

    [theme.breakpoints.up('md')]: {
      marginTop: '100px',
    },
  },

  flexpaymentmethodContainer: {
    marginTop: '30px',
  },

});

export default style;
import { connect } from 'react-redux';

import OrderReview from 'ui/page/orderreview/OrderReview';
import { getCartState } from 'state/orders/selectors';
import { getLoading } from 'state/loading/selectors';
import { getCustomer } from 'state/customer/selectors';
import { sendOrder } from 'state/orders/actions';
import { TOAST_INFO } from 'state/toasts/const';
import { addToast } from 'state/toasts/actions';
import { withRouter } from 'react-router-dom';

export const mapStateToProps = state => ({
  customer: getCustomer(state),
  cart: getCartState(state),
  loading: getLoading(state)['sendorder'] || false,
});

export const mapDispatchToProps = (dispatch, { history }) => ({
  onConfirmOrder: (customer, cart) => {
    const order = {
      id: cart.dateCreated,
      from: customer.name,
      cart: cart.list,
      frominfo: customer,
      transact: customer.paymethod,
    };
    dispatch(sendOrder(order)).then(() => {
      dispatch(addToast(`Your order (${order.id}) has been processed. Expect a call or text from us!`, TOAST_INFO));
      history.push('/');
    });
  },
  onBack: () => {
    history.back();
  },
  onGotoCart: () => {
    history.push('/cart');
  },
});

const OrderReviewContainer = connect(mapStateToProps, mapDispatchToProps)(OrderReview);

export default withRouter(OrderReviewContainer);

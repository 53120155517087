import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPageSelected } from 'state/pages/actions';
import { links } from 'state/pages/reducer';
import { removeItemFromCart, patchItemInCart } from 'state/orders/actions';
import { getCartList } from 'state/orders/selectors';
import { TOAST_INFO } from 'state/toasts/const';
import { addToast } from 'state/toasts/actions';
import { openAskDialog } from 'state/prompter/actions';

import Cart from 'ui/page/Cart';

export const mapStateToProps = state => ({
  cart: getCartList(state),
});

export const mapDispatchToProps = (dispatch, { history }) => ({
  onDidMount: () => dispatch(setPageSelected(links.cart)),
  handleUpdateQty: (item, qty) => {
    console.log('to update', item, qty);
    dispatch(patchItemInCart({ ...item, qty }, item.dateAdded));
  },
  handleDeleteConfirm: item => (
    dispatch(openAskDialog({
      title: `Remove ${item.name}`,
      msg: 'Are you sure to remove?',
    })).then((answer) => {
      if (answer === 'yes') {
        dispatch(removeItemFromCart(item.dateAdded));
        dispatch(addToast(`${item.name} removed from cart.`, TOAST_INFO));
      }
    })
  ),
  onProceedCheckout: () => history.push('/checkout'),
});

const CartContainer = connect(mapStateToProps, mapDispatchToProps)(Cart);

export default withRouter(CartContainer);

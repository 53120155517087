import React from "react";
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContactCard from 'ui/common/ContactCard';
import withTheme from 'ui/withTheme';
import style from 'ui/menu/ElevatedTabs.style';

const withStylesElevatedTabs = withStyles(style);

const ElevatedTabsBody = ({ classes, items, category }) => {
  const theme = useTheme();
  const desktopMode = useMediaQuery(theme.breakpoints.up('md'));
  const tabscssdef = { root: classes.tabsroot, indicator: classes.tabsindicator };
  const tabcssdef = { root: classes.tabroot, wrapper: classes.tabwrapper };
  return (
    <div className={classes.root}>
      <Tabs
        orientation={desktopMode ? 'vertical' : 'horizontal'}
        variant={desktopMode ? 'standard' : 'scrollable'}
        value={category}
        classes={tabscssdef}
      >
        {items.map(item =>(
          <Tab
            classes={tabcssdef}
            key={item.id}
            label={item.name}
            component="a"
            href={`#${item.slug}`}
          />
        ))}
      </Tabs>
      {desktopMode && (
        <ContactCard />
      )}
    </div>
  );
};

ElevatedTabsBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.number,
};

ElevatedTabsBody.defaultProps = {
  category: 0,
};

const ElevatedTabs = withStylesElevatedTabs(ElevatedTabsBody);

export default withTheme(ElevatedTabs);

import { createSelector } from 'reselect';
import { get } from 'lodash';
import { separateLast } from 'helpers/collection';

export const getOrdersState = state => state.orders;

export const getOrdersList = createSelector(getOrdersState, orders => orders.list);

const getDividedOrders = createSelector(getOrdersList, orders => separateLast(orders));

export const getPreviousOrders = createSelector(getDividedOrders, ([previouses]) => previouses);
export const getCartState = createSelector(getDividedOrders, ([a, curr]) => curr);

export const getCart = createSelector(getCartState, cstate => cstate.map);
export const getCartList = createSelector(getCartState, cstate => cstate.list);
export const getCartDateConfirmed = createSelector(getCartState, cstate => get(cstate, 'dateConfirmed', ''));


import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withTheme from 'ui/withTheme';
import style from 'ui/page/Cart.style';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CartItem from 'ui/page/CartItem';

const withStylesCart = withStyles(style);

const CartBody = ({
  classes,
  cart,
  onProceedCheckout,
  handleDeleteConfirm,
  handleUpdateQty,
  history,
}) => {
  return (
    <>
      <div className={classes.background}>
        <Typography variant="h4" component="h1" className={classes.headline}>Your Cart</Typography>
        <Grid container> 
          {cart.length ? (
            <>
              {cart.map(cartitem => (
                <CartItem
                  classes={classes}
                  item={cartitem}
                  key={cartitem.dateAdded}
                  onChangeItem={handleUpdateQty}
                  onRemoveItem={handleDeleteConfirm} />
              ))}
              <Grid item xs={12} className={clsx(classes.flexContainer, classes.outercheckoutGrid)}>
                <div className={classes.outercheckoutButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    classes={{
                      root: classes.checkoutButton,
                      label: classes.checkoutButtonLabel,
                    }}
                    onClick={onProceedCheckout}>
                    Proceed to Checkout
                    <br /><small>(Price will be sent to given contact info)</small>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.flexContainer}>
                <div className={classes.outercheckoutButton}>
                  <Button
                    size="large"
                    classes={{
                      root: classes.backButton,
                    }}
                    onClick={() => history.push('/')}>
                    Back
                  </Button>
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.lineBlock}>No items in cart</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

CartBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onProceedCheckout: PropTypes.func.isRequired,
  handleDeleteConfirm: PropTypes.func.isRequired,
  handleUpdateQty: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const Cart = withStylesCart(CartBody);

export default withTheme(Cart);

const style = theme => ({
  headline: {
    ...theme.classes.headline,
    padding: `${theme.spacing(2)}px 0`,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 16px',
      margin: 0,
      backgroundColor: '#FEF9E4',
    },
  },
  body: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  content: {
    width: '100%',
    minHeight: `calc(100vh - 83px)`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `-14px`,
      width: 830,
      padding: '20px 14px 0',
      background: `linear-gradient(90deg, #FFF 830px, #FEF9E4 830px)`,
    },

    '& table': {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'collapse',
      marginBottom: theme.spacing(3),
      '& tbody': {
        display: 'table-row-group',
      },
      '& thead': {
        display: 'table-header-group',
      },
      '& tr': {
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
      },
      '& th': {
        color: '#fff',
        background: '#00429F',
        fontWeight: '500',
        lineHeight: '1.5rem',
        borderRadius: 10,
        padding: '10px 16px',
      },
      '& td': {
        display: 'table-cell',
        padding: 16,
        fontSize: '0.875rem',
        textAlign: 'left',
        fontWeight: '400',
        lineHeight: 1.43,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        letterSpacing: '0.01071em',
        verticalAlign: 'inherit',
      },
    },
  },

  side: {
    display: 'none',
    '@media (min-width: 1080px)': {
      display: 'block',
      width: 250,
      marginTop: 20,
      marginLeft: 20,
    },
  },
});

export default style;

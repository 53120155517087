const style = theme => ({
  root: {
    paddingRight: theme.spacing(2),
    position: 'fixed',
    zIndex: 2,
    width: `calc(270px - ${theme.spacing(2)}px)`,
    height: 'calc(100vh - 196px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "calc(100% - 28px)",
      height: 'auto',
      display: 'block',
      marginBottom: theme.spacing(),
    },
  },
  tabsroot: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(),
      background: theme.palette.primary.main,
      padding: 10,
      boxShadow: "0px 3px 15px rgba(34, 35, 58, 0.5)",
    },
  },
  tabsindicator: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      borderRadius: theme.spacing(),
      backgroundColor: "rgba(255, 255, 255, .2)",
    },
    [theme.breakpoints.up("md")]: {
      right: 'initial',
    },
  },
  tabroot: {
    textTransform: "initial",
    minWidth: 0,
    [theme.breakpoints.down("sm")]: {
      margin: `0 ${theme.spacing()}px`,
      color: theme.palette.primary.contrastText,
      minHeight: 36,
    },
  },
  tabwrapper: {
    fontSize: 13,
    [theme.breakpoints.up("md")]: {
      display: 'block',
      fontSize: 18,
      textAlign: 'left',
    },
  },
});

export default style;

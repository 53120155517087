const style = theme => {
  const SPACING = theme.spacing();
  return {
    fooditem: {
      padding: SPACING,
    },
    thumbgroup: {
      maxWidth: 172,
      position: 'relative',
      margin: '0 auto',
    },
    thumbfab: {
      backgroundColor: theme.palette.accent.main,
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      left: 'calc(50% - 28px)',
      color: theme.palette.accent.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.accent.light,
      },
    },
    thumb: {
      minWidth: 130,
      width: '100%',
      borderRadius: '50%',
      minHeight: 130,
    },
    defprod: {
      textAlign: 'center',
      maxWidth: 172,
      padding: `${SPACING}px 0`,
      margin: '0 auto',
    },
    foodname: {
      margin: `${SPACING}px 0`,
    },
  };
};

export default style;
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import { updateCustomer, autofillCustomer } from 'state/customer/actions';

import Checkout from 'ui/page/checkout/Checkout';

export const mapStateToProps = state => ({
  orderType: formValueSelector('checkout')(state, 'orderType') || 'delivery',
});

export const mapDispatchToProps = (dispatch, { history }) => ({
  onDidMount: () => dispatch(autofillCustomer()),
  onSubmit: cust => {
    dispatch(updateCustomer(cust));
    history.push('/review');
  },
});

const CheckoutContainer = connect(mapStateToProps, mapDispatchToProps)(Checkout);

export default withRouter(CheckoutContainer);

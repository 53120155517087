import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from 'ui/common/form/RenderTextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ContactCard from 'ui/common/ContactCard';
import style from 'ui/page/ContactPage.style';
import LocationMap from 'ui/common/LocationMap';

const withStylesContact = withStyles(style);

const ModContactCard = withStyles(theme => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))(ContactCard);

export const fieldRequired = value => (value ? undefined : 'This field is required');
export const fieldIsPhone = value => (value.trim() !== '+' ? undefined : 'Please enter a valid phone number.');

const ContactPageBody = ({ sending, handleSubmit, classes }) => (
  <div className={classes.body}>
    <div className={classes.content}>
      <Typography variant="h4" component="h1" className={classes.headline}>Contact Us</Typography>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Field
          component={RenderTextField}
          name="name"
          className={classes.textField}
          label="Name"
          margin="normal"
          validate={[fieldRequired]}
        />
        <Field
          component={RenderTextField}
          name="email"
          className={classes.textField}
          label="Email"
          margin="normal"
          validate={[fieldRequired]}
        />
        <Field
          component={RenderTextField}
          name="phone"
          className={classes.textField}
          label="Phone"
          margin="normal"
          validate={[fieldRequired, fieldIsPhone]}
        />
        <Field
          component={RenderTextField}
          name="message"
          className={classes.textField}
          label="Message"
          margin="normal"
          validate={[fieldRequired]}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={sending}
          className={classes.submit}
        >
          {sending ? 'sending...' : 'Send'}
        </Button>
      </form>
    </div>
    <div className={classes.side}>
      <LocationMap markers={[{ id: 'wildbills', lat: 53.36160430, lng: -104.01884100 }]} />
      <ModContactCard noVisitButton />
    </div>
  </div>
);

ContactPageBody.propTypes = {
  sending: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  submitContact: PropTypes.func.isRequired,
};

const ContactPageForm = reduxForm({
  form: 'contact',
})(ContactPageBody);

const ContactPage = withStylesContact(ContactPageForm);

export default ContactPage;

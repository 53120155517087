import { connect } from 'react-redux';
import { getLoading } from 'state/loading/selectors';
import { postContact } from 'api/jordsys';
import { addToast } from 'state/toasts/actions';
import { TOAST_INFO } from 'state/toasts/const';
import { toggleLoading } from 'state/loading/actions';
import ContactPage from 'ui/page/ContactPage';
import { withRouter } from 'react-router-dom';

export const mapStateToProps = (state) => ({
  sending: getLoading(state)['contact'] || false,
});

export const mapDispatchToProps = (dispatch, { history }) => ({
  onSubmit: (contact) => {
    dispatch(toggleLoading('contact'));
    postContact(contact).then(() => {
      dispatch(toggleLoading('contact'));
      dispatch(addToast('Contact info successfully sent!', TOAST_INFO));
      history.push('/');
    });
  },
});

const ContactPageContainer = connect(mapStateToProps, mapDispatchToProps)(ContactPage);

export default withRouter(ContactPageContainer);

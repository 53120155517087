import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import withTheme from 'ui/withTheme';
import Loader from 'ui/common/Loader';
import FoodMenuItem from 'ui/menu/FoodMenuItem';
import ElevatadTabs from 'ui/menu/ElevatedTabs';
import NoteDialog from 'ui/menu/NoteDialog';
import style from 'ui/menu/FoodMenu.style';

const FoodMenu = ({
  loading,
  gatherMenu,
  foodmenu,
  categories,
  category,
  onAddToCart,
  classes,
}) => {
  const [foodChosen, setFoodChosen] = useState(null);
  const [typeChosen, setTypeChosen] = useState('');
  const [notesDialog, showNotesDialog] = useState(false);
  const theme = useTheme();
  useEffect(() => gatherMenu(), []);
  const desktopMode = useMediaQuery(theme.breakpoints.up('md'));
  const offset = desktopMode ? -160 : -175;
  if (loading) {
    return <Loader page />;
  }
  configureAnchors({ offset, keepLastAnchorHash: true });

  const handleAddAction = (food, sizeType, notes = '') => {
    onAddToCart(food, sizeType, notes);
  };

  const onItemSelected = (food, sizeType, addNotes) => {
    console.log(food['embedded']['wp:term']);
    const catchosen = food['embedded']['wp:term'][0][0];
    setFoodChosen({ ...food, category: catchosen.slug });
    setTypeChosen(sizeType);
    if (addNotes) {
      showNotesDialog(true);
    } else {
      handleAddAction(food, sizeType);
    }
  };

  const onNoteSubmitClick = note => {
    // `current` points to the mounted text input element
    handleAddAction(foodChosen, typeChosen, note);
    showNotesDialog(false);
  };

  const cIdx = categories.map(c => c.slug).indexOf(category.slice(1));

  const catIndex = cIdx === -1 ? 0 : cIdx;

  return (
    <div className={classes.halfColorBody}>
      <Typography variant="h4" component="h1" className={classes.headline}>Menu</Typography>
      <div className={classes.menuColumnBody}>
        <ElevatadTabs items={categories} category={catIndex} />
        <div className={classes.menuSpace} />
        <div className={classes.foodMenuArea}>
          {foodmenu.map(foodcat => (
            foodcat.items && foodcat.items.length > 0) ? (
              <ScrollableAnchor id={foodcat.slug} key={`cat${foodcat.name}-${foodcat.id}`}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.subheadline}>{foodcat.name}</Typography>
                  </Grid>
                  {foodcat.items.map(food => (
                    <FoodMenuItem
                      food={food}
                      key={`food${food.name}-${foodcat.id}-${food.id}`}
                      onOrderingItem={onItemSelected}
                    />
                  ))}
                </Grid>
              </ScrollableAnchor>
            ) : ''
          )}
        </div>
      </div>
      <NoteDialog
        open={Boolean(notesDialog)}
        classes={classes}
        chosen={foodChosen}
        onSubmit={onNoteSubmitClick}
      />
    </div>
  );
};

FoodMenu.propTypes = {
  loading: PropTypes.bool.isRequired,
  gatherMenu: PropTypes.func.isRequired,
  foodmenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.string.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withTheme(withStyles(style)(FoodMenu));

import { lazy } from 'react';
import { connect } from 'react-redux';
import { getPageSelected } from 'state/pages/selectors';
import { fetchPageBySlug } from 'state/pages/actions';
import { getLoading } from 'state/loading/selectors';

import Page from 'ui/page/Page';

export const mapStateToProps = state => ({
  content: getPageSelected(state),
  loading: getLoading(state)['wppage'],
});

export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  onDidMount: () => dispatch(fetchPageBySlug(params.slug)).then((page) => {
    switch (params.slug) {
      case 'fundraisers':
        return lazy(() => import(/* webpackChunkName: "fundraisers" */'ui/page/addons/Fundraisers'));
      default:
        return null;
    }
  }),
});

const PageContainer = connect(mapStateToProps, mapDispatchToProps)(Page);

export default PageContainer;
import {
  SET_MENU,
  SET_MENU_CATEGORIES,
} from 'state/menu/types';
import { toggleLoading } from 'state/loading/actions';
import { getFoodItems, getFoodCategories } from 'api/wprest';

export const setMenu = payload => ({
  type: SET_MENU,
  payload,
});

export const setCategories = payload => ({
  type: SET_MENU_CATEGORIES,
  payload,
})

// thunks

export const fetchMenu = () => dispatch => new Promise((resolve) => {
  dispatch(toggleLoading('foodmenu'));
  return Promise.all([getFoodItems(), getFoodCategories()]).then(([res, rescat]) => {
    dispatch(toggleLoading('foodmenu'));
    dispatch(setMenu(res));
    dispatch(setCategories(rescat));
    resolve({ list: res, categories: rescat });
  });
});

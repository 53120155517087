import doRequest from 'helpers/doRequest';

const objToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).map(([key, val]) => (
    formData.append(
      key,
      typeof val === 'object' ? JSON.stringify(val) : val,
    )
  ));
  return formData;
};

export const postOrder = (params) => (
  doRequest('jord/v1/order', 'POST', { body: objToFormData(params) })
);

export const postContact = (params) => (
  doRequest('jord/v1/contact', 'POST', { body: objToFormData(params) })
);


import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ContactCard from 'ui/common/ContactCard';
import style from 'ui/page/Page.style';
import Loader from 'ui/common/Loader';

const withStylesPage = withStyles(style);

class PageBody extends React.Component {
  state = {
    extraComp: null,
    slug: '',
  };
  componentDidMount() {
    const { onDidMount, match } = this.props;
    onDidMount().then(Comp => {
      this.setState({ slug: match.params.slug });
      const extraComp = Comp !== null ? <Suspense fallback={<Loader />}><Comp /></Suspense> : null;
      this.setState({ extraComp });
    });
  }
  componentDidUpdate() {
    const { match } = this.props;
    const { slug } = this.state;
    if (match.params.slug !== slug) {
      this.setState({ slug: match.params.slug });
      const { onDidMount } = this.props;
      onDidMount().then(Comp => {
        const extraComp = Comp !== null ? 
        <Suspense fallback={<Loader />}><Comp /></Suspense> : null;
        this.setState({ extraComp });
      });
    }
  }
  render() {
    const { classes, content } = this.props;
    const { extraComp } = this.state;
    return (
      <div className={classes.body}>
        <div className={classes.content}>
          <Typography variant="h4" component="h1" className={classes.headline}>{content.title}</Typography>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {extraComp}
        </div>
        <div className={classes.side}>
          <ContactCard fixWidth />
        </div>
      </div>
    );
  }
}

PageBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  content: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  onDidMount: PropTypes.func.isRequired,
};

const Page = withStylesPage(PageBody);

export default Page;

import { connect } from 'react-redux';
import { get } from 'lodash';
import { getPageList } from 'state/pages/selectors';
import { fetchPages } from 'state/pages/actions';
import { beginSession } from 'state/orders/actions';
import { beginCustomer } from 'state/customer/actions';
import { getCartList } from 'state/orders/selectors';
import { getLoading } from 'state/loading/selectors';
import { withRouter } from 'react-router-dom';
import App from 'ui/App';

const noCartFab = ['cart', 'checkout', 'review'];

const detectNoCart = slug => noCartFab.indexOf(slug) === -1;

export const mapStateToProps = (state, { history }) => {
  const cartList = getCartList(state);
  const cartCount = get(cartList, 'length', 0);
  const slug = history.location.pathname.slice(1).replace(/\/$/i,'');
  return {
    wpPages: getPageList(state),
    cartList,
    slug,
    hasCartItems: cartCount > 0 && detectNoCart(slug),
    loading: getLoading(state)['wppages'],
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onDidMount: () => {
    dispatch(beginSession());
    dispatch(beginCustomer());
  },
  gatherPages: () => dispatch(fetchPages()),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(AppContainer);

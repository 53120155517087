import { createSelector } from 'reselect';

export const getRetailersState = state => state.retailers;

export const getRetailers = createSelector(
  [getRetailersState],
  retailers => retailers.list,
);

export const getRetailersList = createSelector(
  [getRetailers],
  retailers => retailers.map((retailer) => {
    const { id, title, acf } = retailer;
    const [lat, lng] = acf.coordinates.split(', ').map(x => parseFloat(x));
    return {
      id,
      name: title.rendered,
      ...acf,
      lat, lng,
    };
  }),
);

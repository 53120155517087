import doRequest from 'helpers/doRequest';

export const getWP = () => doRequest('?_embed');

export const getWPPages = () => doRequest('wp/v2/pages?_embed');

export const getWPPageBySlug = slug => doRequest(`wp/v2/pages?_embed&slug=${slug}`);

export const getFoodItems = () => doRequest('wp/v2/food?_embed&per_page=100');

export const getFoodCategories = () => doRequest('wp/v2/categories');

export const getRetailers = () => doRequest('wp/v2/retailers?_embed&per_page=100');

import { createSelector } from 'reselect';
import { get, camelCase, cloneDeep } from 'lodash';

export const getMenuState = state => state.menu;

export const getMenuList = createSelector(
  [getMenuState],
  menu => menu.list,
);

const parseExtraOptions = (acf) => {
  const { extra_options: extraOptions } = acf;
  if (extraOptions && extraOptions.length) {
    return { ...acf, extraOptions: JSON.parse(extraOptions) };
  } else {
    return { ...acf, extraOptions: [] };
  }
};

export const getFoodMenu = createSelector(
  [getMenuList],
  menu => menu.map(item => ({
    id: item.id,
    name: item.title.rendered,
    image: item._embedded['wp:featuredmedia'][0].media_details,
    embedded: item._embedded,
    ...parseExtraOptions(item.acf),
  })).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else {
      return 1;
    }
  })
);

export const getCategories = createSelector(
  [getMenuState],
  state => state.categories.filter(cat => {
    // console.log(cat, cat.slug !== 'uncategorized');
    return cat.slug !== 'uncategorized';
  }),
);

const catToMap = categories => categories.reduce((acc, curr) => {
  acc[curr.slug] = curr;
  return acc;
}, {});

export const getCategoriesMap = createSelector(
  [getCategories],
  categories => categories.reduce((acc, curr) => {
    acc[camelCase(curr.slug)] = curr;
    return acc;
  }, {})
);

export const getCategoriesMapOrdered = createSelector(
  [getCategoriesMap],
  categories => {
    if (Object.keys(categories).length === 0) return {};
    const { pizza, sides, drinks, ...others } = categories;
    return {
      pizza,
      sides,
      drinks,
      ...others,
    };
  }
);

export const getCategoriesOrdered = createSelector(
  [getCategoriesMap],
  categories => {
    if (Object.keys(categories).length === 0) return [];
    const { pizza, sides, drinks, ...others } = categories;
    return [
      pizza,
      sides,
      drinks,
      ...Object.entries(others).map(([key, val]) => val),
    ];
  }
);

export const getFoodMenuByCategories = createSelector(
  [getFoodMenu, getCategoriesMapOrdered],
  (menu, cats) => {
    if (menu.length == 0 || Object.keys(cats).length == 0) return [];
    return Object.entries(
      menu.reduce((acc, curr) => {
        const catslug = camelCase(curr.embedded['wp:term'][0][0].slug);
        if (!get(acc[catslug], 'items', '')) {
          acc[catslug].items = [];
        }
        acc[catslug].items = [ ...acc[catslug].items, curr ];
        return acc;
      }, cloneDeep(cats))
    ).map(([key, val]) => val)
  },
);

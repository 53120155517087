import { connect } from 'react-redux';
import { getLoading } from 'state/loading/selectors';
import { fetchRetailers } from 'state/retailers/actions';
import { getRetailersList } from 'state/retailers/selectors';
import Retailers from 'ui/retailers/Retailers';

export const mapStateToProps = (state) => ({
  retailers: getRetailersList(state),
  loading: getLoading(state)['retailers'] || false,
});

export const mapDispatchToProps = (dispatch) => ({
  gatherRetailers: () => dispatch(fetchRetailers()),
});

const RetailersContainer = connect(mapStateToProps, mapDispatchToProps)(Retailers);

export default RetailersContainer;

import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const RenderRadioGroup = ({
  input,
  options,
  label,
  classes,
  onChange,
  ...custom
}) => (
  <FormControl component="fieldset">
    {label && (
      <FormLabel component="legend">{label}</FormLabel>
    )}
    <RadioGroup
      name={input.name}
      aria-label={label}
      value={input.value}
      onChange={(ev, val) => {
        input.onChange(val);
        if (onChange) onChange(val);
      }}
      row
      {...custom}
    >
      {options.map(option => (
        <FormControlLabel
          key={JSON.stringify(option)}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
          labelPlacement="end"
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RenderRadioGroup;

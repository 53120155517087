import { createSelector } from 'reselect';
import { get } from 'lodash';

const condensePageObj = (home = '') => (page) => ({
  ...page,
  title: get(page, 'title.rendered', page.title || ''),
  content: get(page, 'content.rendered', page.content || ''),
  path: get(page, 'link', '').replace(home, ''),
});

export const getPageState = state => state.pages;
export const getRawPageList = createSelector(getPageState, pages => pages.listmap);
export const getRawPageListArray = createSelector(getPageState, pages => pages.list);
export const getPageInfo = createSelector(getPageState, pages => pages.info);

export const getPageHomeURL = createSelector(getPageInfo, info => info.home);

export const getPageSelected = createSelector(
  [getPageState, getPageHomeURL],
  (pages, home) => {
    if (pages.selected) {
      return condensePageObj(home)(pages.selected)
    }
    return {};
  },
);

export const getPageList = createSelector(
  [getRawPageListArray, getPageHomeURL],
  (pages, home) => (
    pages.map(condensePageObj(home))
  )
);

export const getPageBySlug = slug => createSelector(getRawPageList, pages => pages[slug]);

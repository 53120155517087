import React from 'react';
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const SPACING_UNIT = 8;

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ebf1f4',
      main: '#02589e',
      dark: '#00306f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#ccc',
      contrastText: '#000',
    },
    accent: {
      light: '#de8472',
      main: '#EB6145',
      contrastText: '#fff',
    },
    spacing: SPACING_UNIT,
  },
  typography: {
    fontFamily: '"Arbutus Slab", serif',
  },
  classes: {
    flex: {
      flexGrow: 1,
    },
    paper: {
      padding: SPACING_UNIT,
    },
    headline: {
      margin: `0 0 ${SPACING_UNIT * 2}px`,
    },
    midscreenCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconbox: {
      width: 48,
      height: 48,
    },
  },
});

theme = responsiveFontSizes(theme);

function withTheme(Component) {
  function WithTheme(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }
  return WithTheme;
}

export default withTheme;

import { 
  INIT_NEW_CART,
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  PATCH_ITEM_CART,
} from 'state/orders/types';
import { toggleLoading } from 'state/loading/actions';
import { getCartState, getCartDateConfirmed } from 'state/orders/selectors';
import { postOrder } from 'api/jordsys';

export const initNewCart = () => ({
  type: INIT_NEW_CART,
});

export const addItemToCart = payload => ({
  type: ADD_ITEM_TO_CART,
  payload,
});

export const removeItemFromCart = payload => ({
  type: REMOVE_ITEM_FROM_CART,
  payload,
});

export const patchItemInCart = (item, key) => ({
  type: PATCH_ITEM_CART,
  payload: { item, key },
});

// thunks

export const beginSession = () => (dispatch, getState) => {
  const state = getState();
  const cart = getCartState(state);
  if (Object.keys(cart).length === 0) {
    dispatch(initNewCart());
  } else if (getCartDateConfirmed(state)) {
    dispatch(initNewCart());
  }
};

export const postAddToCart = (item, type = 'main', qty = 1, notes = '') => dispatch => {
  dispatch(addItemToCart({
    ...item,
    type,
    qty,
    notes,
  }));
};

export const sendOrder = order => dispatch => new Promise((resolve) => {
  dispatch(toggleLoading('sendorder'));
  postOrder(order).then(() => {
    dispatch(toggleLoading('sendorder'));
    dispatch(initNewCart());
    resolve(order);
  });
});

import {
  SET_RETAILERS,
} from 'state/retailers/types';

const initalState = {
  list: [],
};

const reducer = (state = initalState, action = {}) => {
  switch (action.type) {
    case SET_RETAILERS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import RenderTextField from 'ui/common/form/RenderTextField';
import RenderRadioGroup from 'ui/common/form/RenderRadioGroup';
import withTheme from 'ui/withTheme';
import style from 'ui/page/checkout/Checkout.style';

const withStylesCheckout = withStyles(style);

export const fieldRequired = value => (value ? undefined : 'This field is required');
export const fieldIsPhone = value => (value.trim() !== '+' ? undefined : 'Please enter a valid phone number.');
export const fieldIsNumber = value => (!Number.isNaN(value) && Number(value) > 0 ? undefined : 'Please enter valid amount');

const CheckoutBody = ({ classes, handleSubmit, orderType, onDidMount, history }) => {
  useEffect(() => onDidMount(), []);
  return (
    <>
      <div className={classes.background}>
        <Typography variant="h4" component="h1" className={classes.headline}>Checkout</Typography>
        <Typography variant="subtitle2" className={classes.title}>Customer Information for Delivery</Typography>
        <form className={classes.container} onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container>
            <Grid item xs={12} className={classes.flexContainer}>
              <div>
                <Field
                  component={RenderRadioGroup}
                  name="orderType"
                  label="Order Type"
                  options={[{
                    value: 'delivery',
                    label: 'Delivery',
                  }, {
                    value: 'pickup',
                    label: 'Pick-up',
                  }]}
                />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.flexContainer}>
              <div>
                <Field
                  component={RenderTextField}
                  id="name"
                  name="name"
                  className={classes.textField}
                  label="Name"
                  margin="normal"
                  validate={[fieldRequired]}
                />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.flexContainer}>
              <div>
                <Field
                  component={RenderTextField}
                  id="instructions"
                  name="instructions"
                  className={classes.textField}
                  label="Notes/Instructions"
                  margin="normal"
                />
              </div>
            </Grid>
            {orderType === 'delivery' && (
              <Grid item xs={12} className={classes.flexContainer}>
                <div>
                  <Field
                    component={RenderTextField}
                    name="address"
                    className={classes.textField}
                    label="Address"
                    margin="normal"
                    validate={[fieldRequired]}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12} className={classes.flexContainer}>
              <div>
                <Field
                  component={RenderTextField}
                  name="contact"
                  className={classes.textField}
                  label="Contact Number"
                  margin="normal"
                  validate={[fieldRequired, fieldIsPhone]}
                />
              </div>
            </Grid>
            {orderType === 'delivery' && (
              <Grid item xs={12} className={classes.flexpaymentmethodContainer}>
                <Field
                  component={RenderRadioGroup}
                  label="Payment Method"
                  name="paymethod"
                  options={[{
                    value: 'cash',
                    label: 'Cash',
                  }, {
                    value: 'debit',
                    label: 'Debit',
                  }]}
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.flexContainer}>
              <div className={classes.outernextButton}>
                <Button type="button" size="large" className={classes.nextButton} onClick={() => history.goBack()}>
                  Back
                </Button>
                <Button type="submit" variant="contained" color="primary" size="large" className={classes.nextButton}>
                  Next
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

CheckoutBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
}

const CheckoutForm = reduxForm({
  form: 'checkout',
})(CheckoutBody);

const Checkout = withStylesCheckout(CheckoutForm);

export default withTheme(Checkout);

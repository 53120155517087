import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCategoriesOrdered, getFoodMenuByCategories } from 'state/menu/selectors';
import { TOAST_INFO } from 'state/toasts/const';
import { addToast } from 'state/toasts/actions';
import { getLoading } from 'state/loading/selectors';
import { fetchMenu } from 'state/menu/actions';
import { setPageSelected } from 'state/pages/actions';
import { postAddToCart } from 'state/orders/actions';
import { links } from 'state/pages/reducer';
import FoodMenu from 'ui/menu/FoodMenu';

export const mapStateToProps = (state, { location: { hash } }) => ({
  category: hash,
  categories: getCategoriesOrdered(state),
  foodmenu: getFoodMenuByCategories(state),
  loading: getLoading(state)['foodmenu'] || false,
});

export const mapDispatchToProps = (dispatch, { history }) => ({
  gatherMenu: () => {
    dispatch(fetchMenu());
    dispatch(setPageSelected(links));
  },
  onAddToCart: (item, type, note = '') => {
    dispatch(postAddToCart(item, type, 1, note));
    dispatch(addToast(`${item.name} added to cart.`, TOAST_INFO, { link: '/cart', label: 'Go to Cart' }));
  },
  onGoToCart: () => history.push('/cart'),
});

const FoodMenuContainer = connect(mapStateToProps, mapDispatchToProps)(FoodMenu);

export default withRouter(FoodMenuContainer);
